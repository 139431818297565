import React, { useState, useEffect, useMemo } from "react";
import { Typography, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSearchParams } from "react-router-dom";
import AddDocument from "./AddDocument";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

const downloadDocument = (documentLink) => {
  window.open(documentLink);
};

function CollapsibleRow({ designDocs }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Type</TableCell>
            <TableCell>File Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {designDocs.map((doc, index) => (
            <TableRow
              key={index}
              onDoubleClick={() => {
                if (doc.userName !== 'Product Documentation') {
                  downloadDocument(doc.documentLink);
                }
              }}
              style={{ cursor: 'pointer' }}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
              }}
            >
              <TableCell>{doc.fileType}</TableCell>
              <TableCell>{doc.productFileName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function DocumentList(props) {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [prodNames, setProdNames] = useState([]);
  const [prodTypes, setProdTypes] = useState([]);
  const [designDocuments, setDesignDocuments] = useState([]);
  const theme = useTheme();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const documentAdded = () => {
    fetchData();
    props.prelimAdded();
  }

  const fetchData = () => {
    axios
      .get(`/api/Onboarding/documentList/${searchParams.get("id")}`)
      .then((response) => {
        let documentsData = Array.isArray(response.data)
          ? response.data
          : [];

        if (response.data) {
            setDesignDocuments(response.data.filter(doc => doc.fileType.includes('(Set')));
            setData([...documentsData.filter(doc => !doc.fileType.includes('(Set'))]);

            if (!(designDocuments.length > 0)) {
              const productDocRow = {
                userName: 'Product Documentation',
                dateTime: '',
                finalDesignDoc: false,
                fileType: '',
                productFileName: '',
                dscrption: '',
                versionNo: '',
                documentLink: '',
              };
              setData(prevData => [...prevData, productDocRow]);
            }
        }

      })
      .catch((error) => {
        alert("Error.");
      });
  };

  const fetchProducts = () => {
    axios
      .get(`api/Projects/products/${searchParams.get("id")}`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.log(`Error.`);
      });
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (Array.isArray(products)) {
      const productTypes = products.map(product => product.productType);
      const productNames = products.map(product => product.productName);
      setProdTypes(productTypes);
      setProdNames(productNames);
    } else {
      console.error("Error.");
    }
  }, [products]);

  if (props.obSubmit) {
    fetchData();
    props.onObTaskSubmit(false);
  }

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  const sortedData = useMemo(() => {
    const productDocs = data.filter(row => row.userName === 'Product Documentation');
    const otherDocs = data.filter(row => row.userName !== 'Product Documentation');
    return [...productDocs, ...otherDocs];
  }, [data]);

  const columns = useMemo(() => [
    {
      accessorKey: 'userName',
      header: 'User',
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'dateTime',
      header: 'Date/Time',
      Cell: ({ cell, row }) => {
        if (row.original.userName === 'Product Documentation') {
          return null;
        }
        const date = offsetDate(new Date(cell.getValue()));
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      },
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'finalDesignDoc',
      header: 'Final Design',
      Cell: ({ cell }) => (
        cell.getValue() ? <CheckCircleIcon style={{ color: "green" }} /> : null
      ),
      size: 50,
      align: 'center',
      enableResizing: true,
    },
    {
      accessorKey: 'fileType',
      header: 'File Type',
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'productFileName',
      header: 'File Name',
      size: 100,
      align: 'center'
    },
    {
      accessorKey: 'dscrption',
      header: 'Description',
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'versionNo',
      header: 'Version No.',
      size: 50,
      align: 'center',
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: sortedData,
    defaultColumn: {
      maxSize: 100,
      minSize: 20,
      size: 20,
    },
    enableColumnResizing: false,
    enableExpandAll: false,
    enableRowHover: true,
    
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        if (row.original.userName !== 'Product Documentation') {
          downloadDocument(row.original.documentLink);
        }
      },
      style: {
        cursor: 'pointer',
      },
    }),
    enableStickyHeader: true,
    enableRowSelection: false,
    initialState: {
      pagination: { pageIndex: 0, pageSize: 100 },
    },
    enablePagination: true,
    muiTableContainerProps: {
      style: {
        maxHeight: '400px',
        minHeight: '400px',
      },
    },
    muiTableBodyProps: {
      align: 'center',
    },

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        if (row.original.userName === 'Product Documentation') {
          table.setExpanded({ [row.id]: !row.getIsExpanded() });
        }
      },
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
        display: row.original.userName === 'Product Documentation' ? 'block' : 'none',
      },
    }),

    renderDetailPanel: ({ row }) =>
      <CollapsibleRow designDocs={designDocuments} />
  });

  return (
    <div style={{ height: '100%', width: '100%', paddingBottom: '10%' }}>
      <Typography variant="h5" gutterBottom style={{ display: "flex", alignItems: "center", marginTop: '5%', marginBottom: '3%' }}>
        <div style={{ paddingLeft: "40%" }}>
          Document List
        </div>
        <div style={{ flex: 1 }}></div>
        <AddDocument onDocumentAdded={documentAdded} products={products} productNames={prodNames} productTypes={prodTypes} />
      </Typography>

      <MaterialReactTable table={table} />
    </div>
  );
}