import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Button, Typography} from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Authenticated } from "../AuthenticatedComponent";

function createData(timesheetid, weeknumber, userid, year, username, firstname, lastname, email, role, department, date, weekstart, weekend, standardhours, overtime, pto, holidayhours, vacationhours, sickhours, otherhours,  totalhours, status, declinenote) {
  return {
    timesheetid,
    weeknumber,
    userid, 
    year,
    username, 
    firstname, 
    lastname, 
    email, 
    role, 
    department, 
    date,
    weekstart,
    weekend,
    standardhours,
    overtime,
    pto,
    holidayhours,
    vacationhours,
    sickhours,
    otherhours, 
    totalhours,
    status,
    declinenote,
  }
}

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

function getWeekRange(date) {
  return {
      from: moment(date)
          .startOf('week')
          .toDate(),
      to: moment(date)
          .endOf('week')
          .toDate(),
  };
}

const timeTypes = [
  { type: "Regular Work" },
  { type: "Overtime" },
  { type: "PTO" },
  { type: "Holiday" },
  { type: "Other" },
  { type: "Total" }
]

const weekDays = [
  { day: "Sunday" },
  { day: "Monday" },
  { day: "Tuesday" },
  { day: "Wednesday" },
  { day: "Thursday" },
  { day: "Friday" },
  { day: "Saturday" },
];

let totalHours = {
  "Regular Work": 0,
  "Overtime": 0,
  "PTO": 0,
  "Holiday": 0,
  "Other": 0,
  "Total": 0
}

const managerRole = ["Service_TimeSheet_Manager", "SI_TimeSheet_Manager"];
const employeeRole = ["Service_TimeSheet_User", "SI_TimeSheet_User"];
const payrollRole = ["Payroll_Processors"];
const adminRole = ["Admin"];
const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
const hourtypes = ["Regular Work", "Overtime", "PTO", "Holiday", "Other"]

function CollapsibleRow(props) {
  const [weekTotalHours, setWeekTotalHours] = useState(totalHours);
  const [timesArray, setTimesArray] = useState([
      {
          day: "Sunday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
      {
          day: "Monday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
      {
          day: "Tuesday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
      {
          day: "Wednesday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
      {
          day: "Thursday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
      {
          day: "Friday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
      {
          day: "Saturday",
          hours: [
              { type: "Regular Work", time: 0 },
              { type: "Overtime", time: 0 },
              { type: "PTO", time: 0 },
              { type: "Holiday", time: 0 },
              { type: "Other", time: 0 },
              { type: "Total", time: 0 }
          ]
      },
  ]);

  let timeLogArray = [
    {
        day: "Sunday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
    {
        day: "Monday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
    {
        day: "Tuesday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
    {
        day: "Wednesday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
    {
        day: "Thursday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
    {
        day: "Friday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
    {
        day: "Saturday",
        hours: [
            { type: "Regular Work", time: 0 },
            { type: "Overtime", time: 0 },
            { type: "PTO", time: 0 },
            { type: "Holiday", time: 0 },
            { type: "Other", time: 0 },
            { type: "Total", time: 0 }
        ]
    },
  ];

  const handleTransferData = (log) => {
    if (new Date(log.date) >= getWeekRange(new Date(props.row.weekstart)).from && new Date(log.date) <= getWeekRange(new Date(props.row.weekstart)).to && log.userid === props.row.userid) {
        timeLogArray[weekdays.indexOf(log.day)].hours[hourtypes.indexOf(log.hourtype)].time += log.hoursworked;
        timeLogArray[weekdays.indexOf(log.day)].hours[5].time += log.hoursworked;
        totalHours[log.hourtype] += Number(log.hoursworked.toFixed(2));
    }
  }

  const handleUpdateTimeSheetCells = () => {
      totalHours = {
          "Regular Work": 0,
          "Overtime": 0,
          "PTO": 0,
          "Holiday": 0,
          "Other": 0,
          "Total": 0
      }

      props.timeLogsList.map((log) => (
          handleTransferData(log)
      ));
      totalHours["Total"] = totalHours["Regular Work"] + totalHours["Overtime"] + totalHours["PTO"] + totalHours["Holiday"] + totalHours["Other"];
      if (totalHours["Regular Work"] > 40) {
        totalHours["Overtime"] = totalHours["Regular Work"] - 40;
        totalHours["Regular Work"] = 40;
      }
      if (totalHours["Overtime"] > 0) {
        let overtime = totalHours["Overtime"];
        {timeLogArray.map((item1) => {
          {item1.hours.map((item) => {
            if (item.type === "Regular Work" && item.time > 8 ) {
              if (item.time - overtime >= 8) {
                item.time -= overtime;
                timeLogArray[weekdays.indexOf(item1.day)].hours[hourtypes.indexOf("Overtime")].time = overtime;
                overtime = 0;
              } else {
                timeLogArray[weekdays.indexOf(item1.day)].hours[hourtypes.indexOf("Overtime")].time = item.time - 8;
                overtime -= (item.time - 8);
                item.time = 8;
              }
            }
          })}
        })}
      }
      setWeekTotalHours(totalHours);
      setTimesArray(timeLogArray);
  }

    useEffect(() => {
      handleUpdateTimeSheetCells();
      // eslint-disable-next-line
  }, [props.timeLogsList])

  return(
    <>
      <Box sx={{ margin: 1, width: '90vw' }}>
        <Grid container >
          <Grid container flexDirection={"row"}>
              <Stack flex={1} />
              <Stack flex={7} direction={"row"} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                  {weekDays.map((item, index) => (
                      <Stack direction="row" flex={1} justifyContent={"center"} key={index} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                          <Stack marginY={"15px"}>
                              <Typography>{item.day}</Typography>
                              <Stack alignItems={"center"}>
                                  <Typography >
                                      {new Date(dayjs(props.row.weekstart).add(weekDays.findIndex(d => d.day === item.day), 'day')).getMonth()+1}/{new Date(dayjs(props.row.weekstart).add(weekDays.findIndex(d => d.day === item.day), 'day')).getDate()}
                                  </Typography>
                              </Stack>
                          </Stack>
                      </Stack>
                  ))}
              </Stack>
              <Stack flex={1} alignItems={"center"} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                  <Typography fontWeight={"bold"} margin={"15px"}>Total</Typography>
              </Stack>
          </Grid>

          <Grid container flexDirection={"row"} >
            <Stack flex={1} alignItems={"flex-end"}>
                {timeTypes.map((item, index) => (
                    <Box direction="column" paddingRight={"10%"} key={index}>
                        {item.type === "Total" ? (
                            <Typography fontWeight="bold" sx={{ marginY: "16.5px", }}>{item.type}</Typography>
                        ) : (
                            <>
                                <Typography sx={{ marginY: "16.5px", }}>{item.type}</Typography>
                            </>

                        )}
                    </Box>
                ))}
            </Stack>

            {timesArray.map((item1, index) => (
                <Stack flex={1} key={index}>
                    {item1.hours.map((item, index) => (
                        <Box direction="column" key={index} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider'}}>
                            {item.type === "Total" ? (
                                // bottom row
                                <Typography fontWeight="bold" sx={{ margin: "15px", height: "24px" }}>{Number(item.time.toFixed(2)) > 0 ? Number(item.time.toFixed(2)) : ""}</Typography>
                            ) : (
                              <Typography sx={{ margin: "15px", height: "24px" }}>{Number(item.time.toFixed(2)) > 0 ? Number(item.time.toFixed(2)) : ""}</Typography>
                            )}
                        </Box>
                    ))}
                </Stack>
            ))}

            <Stack flex={1}>
                {Object.entries(weekTotalHours).map((key, value) => (
                    <Box direction="column" key={value} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                        {/* right column totals */}
                        <Typography sx={{ margin: "15px", height: "24px", fontWeight: "bold" }} key={value}>{Number(key[1].toFixed(2)) > 0 ? Number(key[1].toFixed(2)) : ""}</Typography>
                    </Box>
                ))}
            </Stack>
          </Grid>
        </Grid>
        {props.row.declinenote !== null ? (
          <Box item sx={{ margin: 2}}>
            <Typography fontWeight={"bold"}>Manager Declined Approval:</Typography>
            <Typography paddingTop={1} paddingLeft={1}>{props.row.declinenote}</Typography>
          </Box>
        ) : (
          null
        )}
      </Box>
    </>
    
  )
}

export default function TimeTable(sheet) {

  const [currentWeekNumber, setCurrentWeekNumber] = useState(sheet.currentWeekNumber);
  const [employeeId, setEmployeeId] = useState(sheet.employeeId);
  const [year, setYear] = useState(sheet.year);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentWeekNumber(sheet.currentWeekNumber);
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.currentWeekNumber])

  useEffect(() => {
    setYear(sheet.year);
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.year])

  useEffect(() => {
    setEmployeeId(sheet.employeeId);
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.employeeId])

  useEffect(() => {
    handleSheetSelect(sheet.currentWeekNumber, sheet.employeeId, sheet.year);
    // eslint-disable-next-line
  }, [sheet.listener])

  const handleSheetSelect = (newWeekNumber, newEmployeeId, year) => {
    setCurrentWeekNumber(newWeekNumber);
    setEmployeeId(newEmployeeId);
    setYear(year);
    sheet.setSheet(newWeekNumber, newEmployeeId, year);
    fetchWeeklyData();
  }

  const fetchWeeklyData = () => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/timeSheetWeekly';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.submitted, sheet.approved, sheet.listener]);

  const loadRows = (timeSheets) => {

    let newRows = [];
    if (timeSheets.length > 0) {
      timeSheets.forEach(ts => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        if ((sheet.currentUserId === ts.empUserId || sheet.subordinateIdsList.includes(ts.empUserId)) && offsetDate(new Date(ts.weekStartDate)) <= new Date() ||
            Authenticated(adminRole) && offsetDate(new Date(ts.weekStartDate)) <= new Date()) {
          newRows.push(createData(
            ts.weekNumber,
            ts.weekNumber,
            ts.empUserId,
            ts.tsYear,
            ts.userName,
            ts.firstName,
            ts.lastName,
            ts.email,
            ts.role,
            ts.department,
            ts.weekStartDate ? `${offsetDate(new Date(ts.weekStartDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })} - ${offsetDate(new Date(ts.weekEndDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}` : "-",
            ts.weekStartDate ? offsetDate(new Date(ts.weekStartDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            ts.weekEndDate ? offsetDate(new Date(ts.weekEndDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            ts.regularHours > 40 ? 40 : ts.regularHours,
            ts.regularHours > 40 ? Number((ts.regularHours - 40).toFixed(2)) : 0,
            ts.ptoHours,
            ts.holidayHours,
            ts.vacationHours,
            ts.sickHours,
            ts.otherHours,
            ts.totalHoursWorked,
            ts.tsStatus,
            ts.declineNote,
          ))
        }
      })
    }
    setRows(newRows);
  }

  const handleExportData = () => {
    //const rowData = rows.map((row) => (row["status"] === "Approved" ? row : row));
    var rowData = rows.reduce(function(result, row) {
      if (row["status"] === "Approved") {
        result.push(row);
      }
      return result;
    }, []);
    rowData.forEach(row => {
      if (row["userid"]) {
        delete row["userid"];
      }
      if (row["year"]) {
        delete row["year"];
      }
      if (row["username"]) {
        delete row["username"];
      }
      if (row["role"]) {
        delete row["role"];
      }
      if (row["weekstart"]) {
        delete row["weekstart"];
      }
      if (row["weekend"]) {
        delete row["weekend"];
      }
      delete row["declinenote"];
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columns =
    [
      {
        accessorKey: 'timesheetid',
        header: 'Time Sheet',
        size: 50,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <>
            {Authenticated(managerRole) || Authenticated(employeeRole) || Authenticated(adminRole)
              ? (
                <>
                  {row.original.timesheetid === currentWeekNumber && row.original.userid === employeeId && row.original.year === year
                  ? (
                    <Button
                      variant="contained"
                      disabled
                      sx={{ paddingX: "3px", paddingY: "0px" }}
                    >
                      Displaying
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => handleSheetSelect(row.original.timesheetid, row.original.userid, row.original.year)}
                      sx={{ padding: "0px" }}
                    >
                      Select
                    </Button>
                  )}
                </>
              ) : (null)
            }
          </>
        ),
      },
      {
        accessorKey: 'firstname',
        header: 'First Name',
        size: 50,
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'lastname',
        header: 'Last Name',
        enableColumnOrdering: false,
        size: 50,
      },
      {
        accessorKey: 'weeknumber',
        header: 'Week #',
        enableColumnOrdering: false,
        size: 15,
      },
      {
        accessorKey: 'date',
        header: 'Week Of',
        size: 90,
        sortingFn: (a, b, columnId) => {
          if (dayjs(b.original[columnId].substring(0, 10)) > dayjs(a.original[columnId].substring(0, 10)))
            return -1;
          else if (dayjs(b.original[columnId].substring(0, 10)) < dayjs(a.original[columnId].substring(0, 10)))
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 60,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId] === "Not Submitted" && a.original[columnId] === "Approved")
            return -1;
          else if (b.original[columnId] === "Approved" && a.original[columnId] === "Not Submitted")
            return 1;
          else if (b.original[columnId] === "Pending Approval" && a.original[columnId] === "Not Submitted")
            return -1;
          else if (b.original[columnId] === "Not Submitted" && a.original[columnId] === "Pending Approval")
            return 1;
          else if (b.original[columnId] === "Pending Approval" && a.original[columnId] === "Approved")
            return -1;
          else if (b.original[columnId] === "Approved" && a.original[columnId] === "Pending Approval")
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'standardhours',
        header: 'Standard Hours',
        size: 40,
      },
      {
        accessorKey: 'overtime',
        header: 'Overtime',
        size: 40,
      },
      {
        accessorKey: 'pto',
        header: 'PTO',
        size: 40,
      },
      {
        accessorKey: 'holidayhours',
        header: 'Holiday',
        size: 40,
      },
      {
        accessorKey: 'otherhours',
        header: 'Other Hours',
        size: 40,
      },
      {
        accessorKey: 'totalhours',
        header: 'Total Hours',
        size: 40,
      },
      
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableExpanding: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    state: {
      showSkeletons: loading,
    },
    layoutMode: 'grid',
    paginationDisplayMode: 'pages',
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'date',
          desc: true,
        },
        {
          id: 'status',
          desc: true,
        }
      ],
      columnPinning: { left: ['mrt-row-expand', 'timesheetid', 'firstname', 'lastname'] },
      pagination: { pageSize: 100, pageIndex: 0 }
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { height: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    muiDetailPanelProps: () => ({
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        {Authenticated(payrollRole) || Authenticated(adminRole)
          ?
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
            >
              Export to csv
            </Button>
          </Box>
          :
            null
          }
      </>
    ),
    renderDetailPanel: ({ row }) =>
      Authenticated(managerRole) || Authenticated(employeeRole) || Authenticated(adminRole) ? <CollapsibleRow key={row.original.timesheetid} row={row.original} listener={sheet.listener} weekTotalHours={sheet.weekTotalHours} timeLogsList={sheet.timeLogsList}/> : null
  });

  return <MaterialReactTable table={table} />;
}
  